<template>
  <div id="app">
<!--    <div class="qq-consult sticky-top mt-5">-->
<!--      <a target="_blank" href="http://wpa.qq.com/msgrd?v=3&uin=2033991622&site=qq&menu=yes">-->
<!--        <img src="http://wpa.qq.com/pa?p=2:2033991622:53"-->
<!--             alt="您好，这里是AdLinke流量变现平台，有什么我可以帮到您？"-->
<!--             title="您好，这里是AdLinke流量变现平台，有什么我可以帮到您？"/>-->
<!--      </a>-->
<!--    </div>-->

    <div class="container">
      <nav class="navbar navbar-expand-lg navbar-light nav-bg sticky-top mt-2">
        <a class="navbar-brand" href="#">
          <img src="./assets/adtie-logo-brand.png" alt="AdLinke logo" style="width: 64px; height: 48px">
          AdLinke流量变现平台
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <p style="visibility: hidden">广告变现 流量变现 聚合广告</p>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav mr-auto">
            <li class="nav-item">
              <a href="#home" class="nav-link">首页</a>
            </li>
            <li class="nav-item">
              <a href="#advantage" class="nav-link">平台优势</a>
            </li>
            <li class="nav-item">
              <a href="#adtype" class="nav-link">广告类型</a>
            </li>
            <li class="nav-item">
              <a href="#adcase" class="nav-link">优秀案例</a>
            </li>
            <li class="nav-item">
              <a href="#contact-us" class="nav-link">联系我们</a>
            </li>
          </ul>
          <form class="form-inline my-2 my-lg-0">
            <a href="http://user.linke-ad.com" target="_blank">
              <button type="button" class="btn btn-sm">
                开发者登录
              </button>
            </a>
            &nbsp;|&nbsp;
            <a href="http://user.linke-ad.com" target="_blank">
              <button type="button" class="btn btn-sm">
                广告主登录
              </button>
            </a>
          </form>
        </div>
      </nav>

      <div id="home" class="home">
        <div class="row">
          <div class="col-6">
            <div class="title-slogan">
              <h1>AdLinke流量变现平台</h1>
              <div class="slogan">
                <h6>专注于为开发者提供高效的广告收益优化解决方案</h6>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="advantage" class="advantage">
        <div style="text-align: center; margin-bottom: 50px">
          <h1>AdLinke平台优势</h1>
        </div>
        <div class="row">
          <div class="col-4">
            <div class="adv-cell">
              <div style="text-align: center">
                <img src="./assets/adv-1.svg" alt="平台优势" class="adv-image"/>
              </div>
              <h6>提高开发者广告收益</h6>
              <p>AdLinke聚合大量优质的广告资源，在提高广告填充的同时，通过大数据进行精准投放，大幅度提高开发者的广告收益。</p>
            </div>
          </div>
          <div class="col-4">
            <div class="adv-cell">
              <div style="text-align: center">
                <img src="./assets/adv-2.svg" alt="平台优势" class="adv-image"/>
              </div>
              <h6>降低广告平台接入成本</h6>
              <p>通过接入AdLinke SDK，开发者可以避免在应用程序中集成多个广告平台所带来的繁琐工作，节省大量时间与精力，更好地专注于应用开发。</p>
            </div>
          </div>
          <div class="col-4">
            <div class="adv-cell">
              <div style="text-align: center">
                <img src="./assets/adv-3.svg" alt="平台优势" class="adv-image"/>
              </div>
              <h6>多样的广告类型</h6>
              <p>AdLinke支持多种广告类型，包括开屏、激励视频、插屏、原生、Banner等，更好地满足开发者的变现需求。</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            <div class="adv-cell">
              <div style="text-align: center">
                <img src="./assets/adv-4.svg" alt="平台优势" class="adv-image"/>
              </div>
              <h6>精准投放</h6>
              <p>AdLinke通过大数据技术进行分析和建模，通过模型对流量做到精准广告投放，助力流量变现收益最大化。</p>
            </div>
          </div>
          <div class="col-4">
            <div class="adv-cell">
              <div style="text-align: center">
                <img src="./assets/adv-5.svg" alt="平台优势" class="adv-image"/>
              </div>
              <h6>提供数据分析</h6>
              <p>AdLinke提供广告数据分析后台，包括广告展示次数、广告点击率等，帮助开发者更好地来分析和了解用户的行为。</p>
            </div>
          </div>
          <div class="col-4">
            <div class="adv-cell">
              <div style="text-align: center">
                <img src="./assets/adv-6.svg" alt="平台优势" class="adv-image"/>
              </div>
              <h6>丰富的广告资源</h6>
              <p>AdLinke除了拥有自有直投品牌广告，同时也对接了大量联盟广告资源，为开发者提供更多的广告资源和更稳定的广告收入来源。</p>
            </div>
          </div>
        </div>
      </div>

      <div id="adtype" class="adtype">
        <div style="text-align: center; margin-bottom: 50px">
          <h1>广告类型</h1>
        </div>
        <div class="row">
          <div class="col-3">
            <div style="text-align: center">
              <img src="./assets/adtype-1.png" alt="开屏"/>
              <h6 style="margin-top: 30px">开屏广告</h6>
            </div>
          </div>
          <div class="col-3">
            <div style="text-align: center">
              <img src="./assets/adtype-2.png" alt="激励视频"/>
              <h6 style="margin-top: 30px">激励视频广告</h6>
            </div>
          </div>
          <div class="col-3">
            <div style="text-align: center">
              <img src="./assets/adtype-3.png" alt="插屏"/>
              <h6 style="margin-top: 30px">插屏广告</h6>
            </div>
          </div>
          <div class="col-3">
            <div style="text-align: center">
              <img src="./assets/adtype-4.png" alt="原生"/>
              <h6 style="margin-top: 30px">原生广告</h6>
            </div>
          </div>
        </div>
      </div>

      <div id="adcase" class="adcase">
        <div style="text-align: center; margin-bottom: 50px">
          <h1>优秀案例</h1>
        </div>
        <div class="row">
          <div class="col-4">
            <div style="text-align: center">
              <img src="./assets/adcase-4.png" alt="案例"/>
            </div>
            <div class="case-desc">
              <p><b>开屏广告</b>是最常见的广告之一，在App启动时展示，增加广告收益的同时，对用户体验产生的影响较小。</p>
            </div>
          </div>
          <div class="col-4">
            <div style="text-align: center">
              <img src="./assets/adcase-5.png" alt="案例"/>
            </div>
            <div class="case-desc">
              <p>观看广告视频翻倍积分是<b>激励视频广告</b>常见的场景之一，广告转化较高，通过奖励来吸引用户观看激励视频，获取高额的收益。</p>
            </div>
          </div>
          <div class="col-4">
            <div style="text-align: center">
              <img src="./assets/adcase-6.png" alt="案例"/>
            </div>
            <div class="case-desc">
              <p>用户分享之后弹出<b>插屏</b>广告是插屏广告常见的形式，转化效果也较好，用户体验影响也较小。</p>
            </div>
          </div>
        </div>
        <div class="row" style="margin-top: 50px">
          <div class="col-4">
            <div style="text-align: center">
              <img src="./assets/adcase-1.png" alt="案例"/>
            </div>
            <div class="case-desc">
              <p>app单独设置任务中心模块，设置签到、观看视频等常见任务，配合激励视频等广告类型，用户体验好，广告收益高</p>
            </div>
          </div>
          <div class="col-4">
            <div style="text-align: center">
              <img src="./assets/adcase-2.png" alt="案例"/>
            </div>
            <div class="case-desc">
              <p>在用户常用的数据菜单中设置<b>插屏广告</b>，增加广告曝光，获取更高的收益</p>
            </div>
          </div>
          <div class="col-4">
            <div style="text-align: center">
              <img src="./assets/adcase-3.png" alt="案例"/>
            </div>
            <div class="case-desc">
              <p>在资讯里穿插<b>原生(信息流)广告</b>，沉浸式视觉效果，在获取收益的同时，用户也有较好的体验</p>
            </div>
          </div>
        </div>
      </div>

      <div id="contact-us" class="foot-info" style="margin-top: 50px;">
        <div class="row">
          <div class="col-3 offset-1" style="margin-top: 30px; margin-bottom: 30px;">
            <p><a href="#home" class="a-font">AdLinke介绍</a></p>
            <p><a href="#contact-us" class="a-font">加入我们</a></p>
            <p><a href="http://user.linke-ad.com" target="_blank" class="a-font">开发者登录</a></p>
            <p><a href="http://user.linke-ad.com" target="_blank" class="a-font">广告主登录</a></p>
          </div>
          <div class="col-4 offset-4" style="margin-top: 30px; margin-bottom: 30px;">
            <div class="contact-us">
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     class="bi bi-telephone-forward-fill" viewBox="0 0 16 16">
                  <path fill-rule="evenodd"
                        d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zm10.761.135a.5.5 0 0 1 .708 0l2.5 2.5a.5.5 0 0 1 0 .708l-2.5 2.5a.5.5 0 0 1-.708-.708L14.293 4H9.5a.5.5 0 0 1 0-1h4.793l-1.647-1.646a.5.5 0 0 1 0-.708z"/>
                </svg>&nbsp;&nbsp;&nbsp;联系电话：18301816221
              </div>
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     class="bi bi-mailbox2"
                     viewBox="0 0 16 16">
                  <path d="M9 8.5h2.793l.853.854A.5.5 0 0 0 13 9.5h1a.5.5 0 0 0 .5-.5V8a.5.5 0 0 0-.5-.5H9v1z"/>
                  <path
                      d="M12 3H4a4 4 0 0 0-4 4v6a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V7a4 4 0 0 0-4-4zM8 7a3.99 3.99 0 0 0-1.354-3H12a3 3 0 0 1 3 3v6H8V7zm-3.415.157C4.42 7.087 4.218 7 4 7c-.218 0-.42.086-.585.157C3.164 7.264 3 7.334 3 7a1 1 0 0 1 2 0c0 .334-.164.264-.415.157z"/>
                </svg>&nbsp;&nbsp;&nbsp;E-mail：business@linke-ad.com
              </div>
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-wechat"
                     viewBox="0 0 16 16">
                  <path
                      d="M11.176 14.429c-2.665 0-4.826-1.8-4.826-4.018 0-2.22 2.159-4.02 4.824-4.02S16 8.191 16 10.411c0 1.21-.65 2.301-1.666 3.036a.324.324 0 0 0-.12.366l.218.81a.616.616 0 0 1 .029.117.166.166 0 0 1-.162.162.177.177 0 0 1-.092-.03l-1.057-.61a.519.519 0 0 0-.256-.074.509.509 0 0 0-.142.021 5.668 5.668 0 0 1-1.576.22ZM9.064 9.542a.647.647 0 1 0 .557-1 .645.645 0 0 0-.646.647.615.615 0 0 0 .09.353Zm3.232.001a.646.646 0 1 0 .546-1 .645.645 0 0 0-.644.644.627.627 0 0 0 .098.356Z"/>
                  <path
                      d="M0 6.826c0 1.455.781 2.765 2.001 3.656a.385.385 0 0 1 .143.439l-.161.6-.1.373a.499.499 0 0 0-.032.14.192.192 0 0 0 .193.193c.039 0 .077-.01.111-.029l1.268-.733a.622.622 0 0 1 .308-.088c.058 0 .116.009.171.025a6.83 6.83 0 0 0 1.625.26 4.45 4.45 0 0 1-.177-1.251c0-2.936 2.785-5.02 5.824-5.02.05 0 .1 0 .15.002C10.587 3.429 8.392 2 5.796 2 2.596 2 0 4.16 0 6.826Zm4.632-1.555a.77.77 0 1 1-1.54 0 .77.77 0 0 1 1.54 0Zm3.875 0a.77.77 0 1 1-1.54 0 .77.77 0 0 1 1.54 0Z"/>
                </svg>&nbsp;&nbsp;&nbsp;微信：18301816221
              </div>
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     class="bi bi-chat-dots-fill" viewBox="0 0 16 16">
                  <path
                      d="M16 8c0 3.866-3.582 7-8 7a9.06 9.06 0 0 1-2.347-.306c-.584.296-1.925.864-4.181 1.234-.2.032-.352-.176-.273-.362.354-.836.674-1.95.77-2.966C.744 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7zM5 8a1 1 0 1 0-2 0 1 1 0 0 0 2 0zm4 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0zm3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
                </svg>&nbsp;&nbsp;&nbsp;QQ：2033991622
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <hr>

    <div class="beian-info">
      <a href="https://beian.miit.gov.cn/">沪ICP备2023004956号</a>&nbsp;Copyright@2023 上海琳珂信息科技有限公司 All Rights Reserved.
    </div>
  </div>

</template>

<script>

export default {
  name: 'App',
}
</script>

<style>

.nav-bg {
  background-color: #e3f2fd;
  height: 60px;
}

.home {
  background-image: url("assets/home-bg.svg");
  height: 100%;
  -webkit-background-size: cover;
  -o-background-size: cover;
}

.title-slogan {
  margin-top: 100px;
  margin-left: 150px;
  margin-bottom: 100px;
  color: #ffffff;
}

.slogan {
  margin-top: 20px;
  margin-left: 10px;
}

.advantage {
  margin-top: 80px;
}

.adv-image {
  width: 80px;
  height: 80px;
  margin-bottom: 20px;
}

.adv-cell {
  margin: 10%;
}

.adtype {
  margin-top: 80px;
}

.adcase {
  margin-top: 80px;
}

.case-desc {
  margin-top: 20px;
  margin-right: 5px;
  margin-left: 5px;
  font-size: 16px;
}

.contact-us {
  font-size: 18px;
}

.foot-info {
  background-color: #e3f2fd;
}

.a-font {
  color: gray;
  text-decoration: none !important;
}

.qq-consult {
  margin-top: 100px;
  margin-right: 80px;
  float: right;
  position: sticky;
}

.beian-info {
  margin-top: 30px;
  text-align: center;
  margin-bottom: 15px;
}

</style>
